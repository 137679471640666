$(function () {
    // Включаем отображение сообщений и ошибок Ajax-запросов в виде флеш-уведомлений
    $(document).on('ajaxSetup', function (event, context) {
        context.options.flash = true;
        context.options.loading = $.oc.stripeLoadIndicator;

        context.options.handleErrorMessage = function (message) {
            $.oc.flashMsg({text: message, class: 'error'})
        };

        context.options.handleFlashMessage = function (message, type) {
            $.oc.flashMsg({text: message, class: type})
        };
    });

    // Включаем всплывающие подсказки Bootstrap 4
    $("[data-toggle='tooltip']").tooltip();

    // Переключаем мобильное меню
    $('.navbar-toggler[data-toggle]').each(function () {
        $(this).sidr({
            source: $(this).data('toggle')
        });
    });
});

function updateBasketIcon(element) {
    $(element).find('i').removeClass('fa-cart-plus').addClass('fa-check');
}

/**
 * Скрывает активное в данный момент модальное окно.
 *
 * @param timeout Задержка закрытия окна в миллисекундах
 */
function hideActiveModal(timeout = 1000) {
    let $modal = $('.modal.show');

    if ($modal.length) {
        setTimeout(function () {
            $modal.modal('hide');
        }, timeout);
    }
}